import { useState } from 'react';

import './Header.scss'

export default function Header() {

    const [isNaviconActive, setNaviconActive] = useState(false);

    const handleNaviconClick = () => {
        setNaviconActive(!isNaviconActive);
    };

    // Add or remove class from the body based on the state
    if (isNaviconActive) {
        document.getElementsByTagName('html')[0].classList.add('shownav');
    } else {
        document.getElementsByTagName('html')[0].classList.remove('shownav');
    }

    const handleNavClick = (event, targetId) => {

        console.log('hello');

        event.preventDefault();
    
        const targetElement = document.getElementById(targetId);
    
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }

        setNaviconActive(!isNaviconActive);
    };

    const handleLogoClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <>
            <header>

                <svg onClick={handleLogoClick} viewBox="0 0 162 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="business-name">
                        <path d="M75.5658 34.0995C74.1822 34.0995 73.0753 33.5461 72.3835 32.7159C71.6917 31.8858 71.1383 30.2255 71.1383 28.0117V19.1567C71.1383 18.3265 71.2767 17.4963 71.415 16.6662C71.5534 15.8359 71.8301 15.4209 72.2452 14.8674C72.6603 14.314 73.2137 14.0373 73.7671 13.7605C74.4589 13.4838 75.1507 13.3455 76.1193 13.3455C77.0878 13.3455 77.9179 13.4838 78.4714 13.7605C79.0248 14.0373 79.5783 14.4523 79.855 14.8674C80.1317 15.4209 80.4084 15.9744 80.5468 16.6662C80.6851 17.358 80.6851 18.1881 80.6851 19.1567V20.5403H77.5029V18.6032C77.5029 17.6347 77.3645 17.0813 77.2261 16.6662C76.9494 16.2511 76.6727 16.1127 76.1193 16.1127C75.4275 16.1127 75.0124 16.3894 74.7357 16.8045C74.4589 17.2196 74.3206 18.0498 74.3206 19.1567V27.8733C74.3206 29.1186 74.4589 30.0871 74.5973 30.6405C74.874 31.194 75.2891 31.4707 75.8425 31.4707C76.396 31.4707 76.9494 31.194 77.2261 30.6405C77.5029 30.0871 77.6412 29.1186 77.6412 27.8733V25.1061H75.9809V22.8924H80.6851V33.9612H79.1632L78.6097 32.0241C78.4714 32.7159 78.0563 33.2694 77.3645 33.6845C76.9494 33.9612 76.2576 34.0995 75.5658 34.0995Z" fill="#151515"/>
                        <path d="M88.8485 34.0994C87.7416 34.0994 86.7731 33.9611 86.0813 33.546C85.3895 33.1309 84.8361 32.5775 84.421 31.7473C84.0059 30.9171 83.8676 29.9486 83.8676 28.7034V13.7605H87.3266V28.7034C87.3266 29.2568 87.3266 29.8103 87.4649 30.2253C87.6033 30.6404 87.6033 30.9171 87.88 31.1939C88.1567 31.4706 88.4334 31.6089 88.9869 31.6089C89.5403 31.6089 89.817 31.4706 90.0938 31.3322C90.3705 31.1939 90.3705 30.7788 90.5088 30.3637C90.6472 29.9486 90.6472 29.3952 90.6472 28.8417V13.7605H94.1062V28.8417C94.1062 30.087 93.9678 31.0555 93.5528 31.8857C93.1377 32.7158 92.7226 33.2693 91.8924 33.6843C90.9239 33.9611 89.9554 34.0994 88.8485 34.0994Z" fill="#151515"/>
                        <path d="M101.024 34.0994C100.055 34.0994 99.3637 33.961 98.6719 33.6843C97.9801 33.4076 97.565 32.9925 97.1499 32.5774C96.7348 32.1623 96.4581 31.4705 96.3198 30.7787C96.3198 29.9486 96.1814 29.1184 96.1814 28.2882V26.4896H99.3637V28.565C99.3637 29.6718 99.502 30.502 99.6404 30.9171C99.7788 31.3322 100.194 31.6089 100.886 31.6089C101.301 31.6089 101.577 31.4705 101.854 31.3322C102.131 31.1938 102.131 30.9171 102.269 30.502C102.408 30.0869 102.408 29.6718 102.408 28.98C102.408 28.2882 102.269 27.5964 101.854 26.9046C101.439 26.2128 101.024 25.6594 100.194 24.9676L98.5335 23.4456C98.1184 23.0306 97.7034 22.6155 97.2883 22.062C96.8732 21.5086 96.5965 21.0935 96.4581 20.4017C96.1814 19.8483 96.1814 19.1565 96.1814 18.4647C96.1814 17.3578 96.3198 16.5276 96.5965 15.8358C96.8732 15.144 97.4266 14.5906 98.1184 14.1755C98.8102 13.7604 99.7788 13.6221 100.886 13.6221C101.854 13.6221 102.546 13.7604 103.099 14.0371C103.653 14.3139 104.206 14.5906 104.483 15.144C104.76 15.6975 105.036 16.2509 105.175 16.9427C105.313 17.6345 105.452 18.4647 105.452 19.2948V20.5401H102.269V19.0181C102.269 18.4647 102.269 17.9112 102.131 17.4962C101.993 17.0811 101.854 16.8044 101.716 16.5276C101.577 16.2509 101.301 16.2509 100.886 16.2509C100.471 16.2509 100.194 16.3893 100.055 16.3893C99.9171 16.3893 99.6404 16.8044 99.6404 17.0811C99.6404 17.3578 99.502 17.7729 99.502 18.188C99.502 18.603 99.502 19.0181 99.6404 19.2948C99.7788 19.5716 99.9171 19.8483 100.194 20.125C100.471 20.4017 100.747 20.6784 101.162 21.0935L102.961 22.8922C103.791 23.7224 104.483 24.6909 105.036 25.521C105.59 26.3512 105.728 27.4581 105.728 28.565C105.728 29.8102 105.59 30.7787 105.313 31.6089C105.036 32.439 104.483 32.9925 103.791 33.5459C103.099 33.961 102.131 34.0994 101.024 34.0994Z" fill="#151515"/>
                        <path d="M109.187 33.9611V16.3893H106.558V13.7605H115.137V16.3893H112.646V33.9611H109.187Z" fill="#151515"/>
                        <path d="M121.778 34.0995C120.671 34.0995 119.703 33.8228 118.873 33.4077C118.181 32.9927 117.627 32.3009 117.212 31.4707C116.797 30.6405 116.659 29.5337 116.659 28.2884V19.0183C116.659 17.9114 116.797 16.8045 117.212 15.9744C117.627 15.1441 118.042 14.4523 118.873 14.0373C119.564 13.6222 120.533 13.3455 121.778 13.3455C122.747 13.3455 123.438 13.4838 123.992 13.7605C124.545 14.0373 125.237 14.4523 125.514 15.0058C125.929 15.5592 126.206 16.1127 126.344 16.8045C126.482 17.4963 126.621 18.3265 126.621 19.1567V28.2884C126.621 29.5337 126.482 30.6405 126.067 31.4707C125.652 32.3009 125.237 32.9927 124.545 33.4077C123.854 33.8228 122.885 34.0995 121.778 34.0995ZM121.778 31.4707C122.193 31.4707 122.47 31.3323 122.747 31.194C123.023 31.0556 123.162 30.6405 123.162 30.0871C123.3 29.672 123.3 28.9802 123.3 28.2884V18.8799C123.3 18.0498 123.162 17.358 123.023 16.8045C122.885 16.2511 122.47 16.1127 121.916 16.1127C121.363 16.1127 120.81 16.3894 120.671 16.8045C120.533 17.2196 120.395 17.9114 120.395 18.8799V28.2884C120.395 28.9802 120.395 29.672 120.533 30.0871C120.671 30.5022 120.81 30.9173 120.948 31.194C120.948 31.3323 121.363 31.4707 121.778 31.4707Z" fill="#151515"/>
                        <path d="M75.9811 60.3878C74.8742 60.3878 74.0441 60.1111 73.2139 59.696C72.3837 59.2809 71.9687 58.589 71.5536 57.7588C71.1385 56.9287 71 55.8219 71 54.5767V45.7216C71 44.7531 71.1384 43.9228 71.2767 43.231C71.4151 42.5392 71.6918 41.8475 72.1069 41.2941C72.522 40.7407 72.9372 40.3256 73.629 40.0489C74.3208 39.7721 75.0126 39.6338 75.9811 39.6338C76.8113 39.6338 77.5029 39.7721 78.1947 40.0489C78.8865 40.3256 79.3016 40.6022 79.7167 41.1556C80.1318 41.5707 80.4086 42.2626 80.547 42.8161C80.6853 43.5079 80.8237 44.1997 80.8237 45.0298V47.7969H77.5029V45.1682C77.5029 44.1997 77.3646 43.5079 77.2262 42.9544C77.0878 42.401 76.6728 42.2626 76.1193 42.2626C75.4275 42.2626 75.0124 42.5393 74.8741 42.9544C74.5974 43.5079 74.5975 44.1997 74.5975 45.0298V54.4383C74.5975 55.1301 74.5974 55.6834 74.7357 56.0985C74.8741 56.5136 75.0124 56.9287 75.1508 57.067C75.4275 57.3437 75.7044 57.3439 75.9811 57.3439C76.5345 57.3439 76.9496 57.0672 77.088 56.6521C77.3647 56.237 77.3647 55.4067 77.3647 54.2998V51.8095H80.6852V54.7149C80.6852 55.9601 80.547 57.0672 80.1319 57.8973C79.8552 58.7275 79.3016 59.4191 78.6098 59.8342C78.0564 60.2493 77.088 60.3878 75.9811 60.3878Z" fill="#151515"/>
                        <path d="M83.729 60.2495V40.049H89.2634C90.3703 40.049 91.3388 40.1873 91.8922 40.7408C92.4457 41.1558 92.8608 41.7093 92.9991 42.5394C93.1375 43.3696 93.2759 44.3381 93.2759 45.445C93.2759 46.5519 93.1375 47.5204 92.8608 48.2122C92.584 48.904 92.0306 49.3191 91.2004 49.5958C91.6155 49.5958 91.8922 49.8725 92.169 50.0109C92.4457 50.1492 92.584 50.5643 92.8608 50.9794C93.1375 51.3945 93.1375 51.9479 93.1375 52.6397C93.1375 53.3315 93.2759 54.1617 93.2759 55.1302V55.822C93.2759 56.0987 93.2759 56.5138 93.2759 57.0672C93.2759 57.6207 93.2759 58.0358 93.2759 58.4508C93.2759 58.8659 93.2759 59.281 93.2759 59.6961C93.2759 60.1112 93.2759 60.2495 93.2759 60.2495H89.8168C89.8168 60.2495 89.8168 59.9728 89.8168 59.6961C89.8168 59.281 89.8168 58.8659 89.8168 58.3125C89.8168 57.759 89.8168 57.2056 89.8168 56.5138C89.8168 55.822 89.8168 55.2686 89.8168 54.5768V52.9164C89.8168 52.363 89.8168 51.8096 89.6785 51.5328C89.5401 51.2561 89.4018 50.9794 89.125 50.841C88.8483 50.7027 88.4332 50.7027 88.0182 50.5643H86.9113V60.1112L83.729 60.2495ZM87.0496 48.3506H88.4332C88.8483 48.3506 89.125 48.2122 89.4018 48.0738C89.6785 47.9355 89.8168 47.6588 89.8168 47.1053C89.9552 46.6902 89.9552 45.9984 89.9552 45.1683V45.0299C89.9552 44.3381 89.9552 43.7847 89.8168 43.3696C89.8168 42.9545 89.5401 42.6778 89.4018 42.5394C89.125 42.4011 88.71 42.2627 88.2949 42.2627H87.188L87.0496 48.3506Z" fill="#151515"/>
                        <path d="M96.3199 60.2495V40.049H103.791V42.6778H99.6406V48.4889H103.515V51.1178H99.6406V57.6207H103.93V60.2495H96.3199Z" fill="#151515"/>
                        <path d="M105.175 60.2495L108.357 40.049H112.785L116.105 60.2495H112.923L112.37 56.6522H108.772L108.357 60.2495H105.175ZM109.187 54.1617H112.093L110.571 44.0614L109.187 54.1617Z" fill="#151515"/>
                        <path d="M119.426 60.2495V42.6778H116.797V40.049H125.375V42.6778H122.885V60.2495H119.426Z" fill="#151515"/>
                        <path d="M127.312 60.2495V40.049H130.772V60.2495H127.312Z" fill="#151515"/>
                        <path d="M136.444 60.2495L132.985 40.049H136.168L137.69 49.1807L138.658 55.6836H138.796L139.627 49.1807L140.872 40.049H144.054L140.872 60.2495H136.444Z" fill="#151515"/>
                        <path d="M145.991 60.2495V40.049H153.463V42.6778H149.312V48.4889H153.186V51.1178H149.312V57.6207H153.601V60.2495H145.991Z" fill="#151515"/>
                        <path d="M71.2766 86.538V66.3374H74.7356V74.5006H77.7795V66.3374H81.2385V86.538H77.7795V77.1295H74.7356V86.538H71.2766Z" fill="#151515"/>
                        <path d="M89.2634 86.6763C88.1566 86.6763 87.188 86.3995 86.4962 85.9844C85.8044 85.5694 85.1126 85.0158 84.8359 84.0473C84.5592 83.0787 84.2825 82.1104 84.2825 80.8651V71.5949C84.2825 70.488 84.4208 69.3811 84.8359 68.5509C85.251 67.7208 85.6661 67.0291 86.4962 66.614C87.188 66.199 88.1565 65.9222 89.4018 65.9222C90.3703 65.9222 91.0621 66.0606 91.6156 66.3373C92.169 66.614 92.7224 67.029 93.1375 67.4441C93.5526 67.8591 93.8293 68.5509 93.9677 69.2427C94.106 69.9345 94.2444 70.7647 94.2444 71.5949V80.8651C94.2444 82.1104 94.106 83.2171 93.691 84.0473C93.2759 84.8774 92.8608 85.5694 92.169 85.9844C91.4772 86.3995 90.5087 86.6763 89.2634 86.6763ZM89.2634 84.0473C89.6785 84.0473 89.9552 83.9089 90.232 83.7705C90.5087 83.4938 90.647 83.2171 90.647 82.6637C90.647 82.2486 90.7854 81.5569 90.7854 80.8651V71.4566C90.7854 70.6265 90.647 69.9347 90.5087 69.3812C90.3703 68.8278 89.9552 68.6894 89.4018 68.6894C88.8483 68.6894 88.2949 68.9662 88.1566 69.3812C88.0182 69.7963 87.8798 70.4881 87.8798 71.4566V80.8651C87.8798 81.5569 87.8798 82.2486 88.0182 82.6637C88.0182 83.0787 88.2949 83.4938 88.4333 83.7705C88.5716 84.0473 88.8484 84.0473 89.2634 84.0473Z" fill="#151515"/>
                        <path d="M101.993 86.6763C100.886 86.6763 99.9172 86.538 99.0871 86.1229C98.3952 85.7078 97.8418 85.1544 97.4267 84.3242C97.0117 83.494 96.8733 82.5255 96.8733 81.2803V66.3374H100.332V81.2803C100.332 81.8337 100.332 82.3872 100.471 82.8023C100.609 83.2173 100.609 83.494 100.886 83.7708C101.162 84.0475 101.439 84.1859 101.993 84.1859C102.546 84.1859 102.823 84.0475 103.099 83.9091C103.376 83.7708 103.376 83.3557 103.515 82.9406C103.653 82.5255 103.653 81.9721 103.653 81.4187V66.3374H107.112V81.4187C107.112 82.6639 106.974 83.6324 106.558 84.4626C106.143 85.2927 105.728 85.8462 104.898 86.2613C104.206 86.538 103.238 86.6763 101.993 86.6763Z" fill="#151515"/>
                        <path d="M114.307 86.6766C113.338 86.6766 112.646 86.5382 111.955 86.2615C111.401 85.9848 110.848 85.5696 110.433 85.1545C110.017 84.7394 109.879 84.0476 109.602 83.3558C109.464 82.664 109.326 81.8338 109.326 80.8653V79.0668H112.508V81.1422C112.508 82.2491 112.646 82.9409 112.785 83.4943C112.923 84.0477 113.338 84.1861 114.03 84.1861C114.445 84.1861 114.722 84.0477 114.998 83.9094C115.275 83.771 115.275 83.4942 115.414 83.0791C115.552 82.664 115.552 82.2489 115.552 81.5571C115.552 80.8653 115.414 80.1735 114.998 79.4817C114.583 78.7899 114.168 78.2365 113.338 77.5447L111.678 76.0227C111.263 75.6076 110.848 75.1926 110.433 74.6391C110.017 74.0857 109.741 73.6706 109.602 72.9788C109.326 72.4254 109.326 71.7337 109.326 71.0419C109.326 69.935 109.464 69.1049 109.741 68.2747C110.017 67.4445 110.571 67.0295 111.263 66.6144C111.955 66.1993 112.923 66.0608 114.03 66.0608C114.998 66.0608 115.69 66.1992 116.244 66.4759C116.797 66.7526 117.351 67.0295 117.627 67.5829C117.904 67.998 118.181 68.6898 118.319 69.3816C118.457 70.0734 118.596 70.9035 118.596 71.7337V72.9788H115.414V71.5952C115.414 71.0418 115.414 70.4885 115.275 70.0734C115.137 69.6583 114.998 69.3814 114.86 69.1047C114.722 68.828 114.445 68.828 114.03 68.828C113.615 68.828 113.338 68.828 113.2 69.1047C113.061 69.3814 112.785 69.2431 112.785 69.5198C112.646 69.7965 112.646 70.2116 112.646 70.6267C112.646 71.0418 112.646 71.457 112.785 71.7337C112.923 72.0104 113.061 72.287 113.338 72.5637C113.615 72.8404 113.892 73.1173 114.307 73.5324L116.105 75.3309C116.935 76.1611 117.627 77.1297 118.181 77.9599C118.734 78.9284 118.873 79.8968 118.873 81.0037C118.873 82.2489 118.734 83.2174 118.457 84.0476C118.181 84.8778 117.627 85.4312 116.935 85.8463C116.382 86.5381 115.414 86.6766 114.307 86.6766Z" fill="#151515"/>
                        <path d="M121.225 86.538V66.3374H128.696V68.9662H124.545V74.7774H128.419V77.4062H124.545V83.9091H128.835V86.538H121.225Z" fill="#151515"/>
                    </g>
                    <path d="M44.1057 52.2681L61 49.8969L44.1057 40.7048L27.1664 43.0685V52.6583L32.6404 55.6298V67.1931C32.6404 73.1361 30.1808 75.5973 26.3265 76.1601C25.8466 76.2276 25.3817 76.2501 24.9393 76.2426L25.0293 35.8499C25.0593 27.7759 27.3463 21.5027 31.793 17.4357C32.3704 18.6063 32.6853 20.2196 32.6853 22.3356V28.999L49.6246 38.2436V31.5277C49.6696 27.7684 48.6948 25.6298 46.9776 24.6993L32.4904 16.8354C35.2649 14.5543 38.7967 13.046 43.0934 12.4457C47.4126 11.8454 50.9369 12.3557 53.7189 13.8414L36.7721 4.6418C33.9901 3.15605 30.4583 2.6458 26.1466 3.2461C14.2688 4.91944 8.14247 13.3687 8.08998 26.6578L8 69.8494C8 78.3512 10.5045 84.1516 15.3786 86.8004L32.3179 96C27.4438 93.3512 24.9393 87.5508 24.9393 79.049L24.9468 76.918L39.6515 84.9019C40.6263 85.4122 41.8336 85.5548 43.2733 85.3672C47.1276 84.8119 49.5422 82.3507 49.5422 76.4002L49.5872 61.1226L44.1132 58.1361V50.017L44.1057 52.2681Z" fill="black"/>
                    <path d="M43.0675 12.25C54.8778 10.5871 61.0449 17.2833 60.9925 30.4818V36.2757L49.5939 37.8938V31.3095C49.5939 25.4037 47.1839 23.5097 43.3894 24.0615C39.5424 24.6133 37.1324 27.156 37.1324 33.0618L37.0426 77.6011C37.0426 83.5068 39.4526 85.2592 43.2995 84.7521C47.1465 84.2003 49.5564 81.7545 49.5564 75.8413L49.6014 63.642L44.1378 64.425V51.9125L61 49.5562L60.9476 73.4924C60.9027 86.6909 54.788 95.0946 42.9253 96.7501C31.115 98.4129 24.9479 91.7167 25.0003 78.5183L25.0901 35.6418C25.0976 22.2644 31.2123 13.8606 43.0675 12.25Z" fill="white"/>
                    
                </svg>

                <button className="navicon" onClick={handleNaviconClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

            </header>
            <nav>
                <a href="#headline" onClick={(e) => handleNavClick(e, 'headline')}>
                    Home
                </a>
                <a href="#theway" onClick={(e) => handleNavClick(e, 'theway')}>
                    How It Works
                </a>
                <a href="#services" onClick={(e) => handleNavClick(e, 'services')}>
                    What We Do
                </a>
                <a href="#design" onClick={(e) => handleNavClick(e, 'design')}>
                    Design Work
                </a>
                <a href="#pricing" onClick={(e) => handleNavClick(e, 'pricing')}>
                    Our Pricing
                </a>
                <a href="#questions" onClick={(e) => handleNavClick(e, 'questions')}>
                    Got Questions?
                </a>
                <a href="#talk" onClick={(e) => handleNavClick(e, 'talk')}>
                    Let's Talk
                </a>
            </nav>
        </>
    )
}
  