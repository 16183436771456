import React from 'react';
import ReactDOM from 'react-dom/client';
import Gusto from './Gusto';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PFBM32W'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Gusto />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
